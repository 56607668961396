<template>
  <v-container>
    <div v-if="!companyData">
      <div class="not-found-company">
        <v-progress-circular indeterminate />
      </div>
    </div>
    <v-row v-else justify="start" dense class="ma-0 pa-0 cards-container ">
      <v-col v-for="(config, index) in lista" :key="index">
        <a></a>
        <v-card class="ma-0 pa-0" :elevation="cardElevation" :loading="loading" :color="config.cor_background"
          @click.stop="go(config.link)">
          <v-card-text :class="`${config.cor_text}--text text-center `">
            <v-row dense>
              <v-col cols="12">
                <template v-if="config.icon_img">
                  <v-img :src="config.icon_img" :alt="config.titulo"
                    :style="{ width: config.icon_size + 'px', height: config.icon_size + 'px', }"
                    class="text-center ma-auto"></v-img>
                </template>
                <v-icon v-else :size="config.icon_size">{{ config.icon }}</v-icon>
              </v-col>
            </v-row>
            <v-row dense v-if="!config.icon_img || env !== 'https://api.gssma.com.br'">
              <!-- <v-col cols="12">
                <template>
                  <p width="100">
                    {{ config.titulo }}
                  </p>
                </template>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data: () => ({
    loading: false,
    env: process.env.VUE_APP_API_URL
  }),
  computed: {
    ...mapGetters(['modulos', 'companyData']),
    // quando for renderizado no mobile as bordas irão sumir
    cardElevation() {
      return this.$vuetify.breakpoint.smAndDown ? '0' : '1';
    },
    lista() {
      if (this.modulos === undefined) {
        return []
      }
      return this.modulos.map(e => {
        //e.icon_img = e.icon_img.replace('http:', 'https:')
        return e;
      })
    }
  },
  methods: {
    go(val) {
      if(val.startsWith('http')) {
        window.open(val, '_blank')
        return
      }
      
      if (this.$router.currentRoute.name !== val) {
        this.$router.push({ name: val });
      }
    },
  },
  mounted() {
    this.$store.dispatch('modulos', 'modulos/listar/?local=TODOS')
  }
}
</script>
<style scoped>
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 0.5rem;
}

.not-found-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.cols {
  display: flex;
  justify-content: center; 
  align-items: flex-start;
}
</style>
